import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from 'react';
import {
  CommonButton,
  CommonDropDown,
  CommonTextInput,
  Header,
  LocalAlert,
  TableHeaderTitle,
} from '../../commonComponents';
import Loader from '../../commonComponents/Loader';
import {useDispatch} from 'react-redux';
import AsyncThunks from '../../redux/AsyncThunks';
import {useTableData} from '../../hooks';
import {useFollowUps} from '../../hooks/SuperDoctor';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';
import DepartmentBodySection from './DepartmentBodySection';

function CreateDepartmentForm() {
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState('');
  const [lName, setLName] = useState({});
  const [loading, setLoading] = useState(false);
  const [followups, setfollowups] = useState('');
  const [groupingBy, setGroupingBy] = useState('');
  const bodySectionRef = React.useRef();
  const dispatch = useDispatch();
  const table = useTableData(true);
  const {id} = useParams();
  const navigate = useNavigate();
  const {followUpQuestions} = useFollowUps();
  const [showOutputToPatient, setShowOutputToPatient] = useState(false);
  const [hasScore, setHasScore] = useState(false);
  const [subspecialtySequentially, setsubspecialtySequentially] =
    useState(false);
  const [outputDesign, setOutputDesign] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [showAlert, setShowAlert] = useState(false);

  const getSingleDepartment = useCallback(async () => {
    const department = await table.get.department(+id);
    setLName(department.lname);
    // setBodySection(prev => ({...prev, department_id: department?.id}));
    setServerImage(department.image_url);
    setfollowups(department.follow_up_question?.id);
    setGroupingBy(department.symptom_grouping_by);
    setShowOutputToPatient(department.show_output_to_patient);
    setHasScore(department.has_score);
    setsubspecialtySequentially(
      department.present_all_subspecialty_sequentially,
    );
    setOutputDesign(department.output_design);
  }, [id, table.get]);

  useEffect(() => {
    if (id) {
      getSingleDepartment();
    }
  }, [id, getSingleDepartment]);

  const prepareFormData = () => {
    let formData = new FormData();
    formData.append('symptom_grouping_by', groupingBy);
    formData.append('output_design', outputDesign);
    for (const key of Object.keys(lName)) {
      formData.append('lname[' + key + ']', lName[key]);
    }
    formData.append('follow_up_question_id', followups);
    formData.append('show_output_to_patient', showOutputToPatient);
    formData.append('has_score', hasScore);
    formData.append(
      'present_all_subspecialty_sequentially',
      subspecialtySequentially,
    );
    image && formData.append('image', image);
    return formData;
  };

  const inTheEnd = action => {
    action
      .unwrap()
      .then(res => {
        navigate(`/departments`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateInput = () => {
    if (!groupingBy) {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Please provide system group by');
      return false;
    } else if (
      Object.keys(lName).length === 0 ||
      Object.values(lName)[0] === ''
    ) {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Please provide department');
      return false;
    } else {
      return true;
    }
  };

  const createDepartmentform = id => {
    if (validateInput()) {
      let params = prepareFormData();
      setLoading(true);
      inTheEnd(dispatch(AsyncThunks.createDepartment({params, id})));
    } else {
      console.log('Someting went wrong.');
    }
  };

  const updateDepartment = () => {
    if (validateInput()) {
      let params = prepareFormData();
      setLoading(true);
      inTheEnd(dispatch(AsyncThunks.updateDepartment({params, id})));
    } else {
      console.log('Someting went wrong.');
    }
  };

  const groupingByData = [
    {name: 'subspecialty'},
    {name: 'category'},
    {name: 'body_part'},
  ];

  const outputDesignData = [
    {name: 'SyTracker', id: 'sytracker'},
    {name: 'SyTracker Short', id: 'sytracker-short'},
    {name: 'Tabular', id: 'tabular'},
    {name: 'Mind Screen', id: 'mind-screen'},
    {name: 'Thank you', id: 'thank-you'},
    {name: 'Post Module Test', id: 'post-module-test'},
    {name: 'Allergies', id: 'allergies'},
    {name: 'Nutrition', id: 'nutrition'},
    {name: 'Rheumacare', id: 'rheumacare'},
    {name: 'C3 Medicare', id: 'c3-medicare'},
    {name: 'Rheumacare Combined Output', id: 'rheumacare_combined_output'},
    {name: 'Smilex', id: 'smilex'},
    {name: 'Stayon', id: 'stayon'},
  ];

  return (
    <Box>
      <Header />
      <Box sx={{margin: 2}}>
        <TableHeaderTitle
          title={id ? 'Update Department :-' : 'Create Department :-'}
        />
        <CommonDropDown
          formControlStyle={{width: '100%'}}
          label="Followups"
          labelId="Select Followups"
          onChange={setfollowups}
          value={followups}
          selectStyle={{width: '100%'}}
          data={followUpQuestions}
          setData={'id'}
          showData={'descriptor_code'}
          showClose
        />
        <CommonDropDown
          formControlStyle={{width: '100%', margin: '15px 0'}}
          label=" Symptom Grouping By "
          labelId="symptom_grouping_by"
          onChange={setGroupingBy}
          value={groupingBy}
          selectStyle={{width: '100%'}}
          data={groupingByData}
          setData={'name'}
          showData={'name'}
        />
        <CommonDropDown
          formControlStyle={{width: '100%', margin: '5px 0 15px 0'}}
          label=" Output Design "
          labelId="output_design"
          onChange={setOutputDesign}
          showClose
          value={outputDesign}
          selectStyle={{width: '100%'}}
          data={outputDesignData}
          setData={'id'}
          showData={'name'}
        />
        <LanguageDropdown
          inputValue={lName}
          onChange={setLName}
          label="Department"
        />
        <div style={{marginTop: '20px'}}>
          {image && (
            <img
              style={{maxWidth: '200px', maxHeight: '200px'}}
              src={URL.createObjectURL(image)}
              alt="department"
            />
          )}
          {!image && serverImage && (
            <img
              style={{maxWidth: '200px', maxHeight: '200px'}}
              src={serverImage}
              alt="department-img"
            />
          )}
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <label htmlFor="file-input">
            <Button
              variant="contained"
              sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
              component="span">
              Attach Image
            </Button>
          </label>
          <CommonTextInput
            id="file-input"
            style={{visibility: 'hidden'}}
            type={'file'}
            onChange={setImage}
          />
          {id && (
            <div style={{position: 'absolute', right: '13px'}}>
              <Button
                variant="contained"
                sx={{
                  width: 'auto',
                  backgroundColor: '#405672',
                  marginTop: '14px',
                }}
                component="span"
                onClick={() => {
                  bodySectionRef.current.add();
                }}>
                Add body Section
              </Button>
            </div>
          )}
        </div>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={showOutputToPatient} />}
            label="Show Output To Patient?"
            onChange={() => setShowOutputToPatient(!showOutputToPatient)}
          />
          <FormControlLabel
            control={<Checkbox checked={hasScore} />}
            label="Has Score?"
            onChange={() => setHasScore(!hasScore)}
          />
          <FormControlLabel
            control={<Checkbox checked={subspecialtySequentially} />}
            label="Present All Subspecialty Sequentially"
            onChange={() =>
              setsubspecialtySequentially(!subspecialtySequentially)
            }
          />
        </FormGroup>
      </Box>

      <CommonButton
        style={{width: '98%', backgroundColor: '#405672', marginTop: 2}}
        label={'Save'}
        component="span"
        onClick={() => {
          id ? updateDepartment() : createDepartmentform();
        }}
      />
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <DepartmentBodySection ref={bodySectionRef} />
      <Loader loading={loading} />
    </Box>
  );
}

export default CreateDepartmentForm;
